<template>

    <!-- Common Banner Area -->
    <LoginBanner />

    <!--  Common Author Area -->
    <EmailLogin />


</template>
<script>
import LoginBanner from '@/components/user/LoginBanner.vue'
import CommonAuthorFour from '@/components/user/CommonAuthorFour.vue'
import EmailLogin from "@/components/user/EmailLogin.vue";


export default {
    name: "LoginExternalView",
    components: {
      EmailLogin,
        LoginBanner, CommonAuthorFour
    }
};
</script>
