<template>
    <div class="col-lg-3  col-md-6 col-sm-12 col-12">
        <div class=" dropdown_passenger_area">
            <p>Hóspedes e Quartos </p>
            <div class="dropdown">
                <button class="dropdown-toggle final-count" data-toggle="dropdown" type="button"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ numberAdults }} Adultos {{ numberChildren }} Crianças {{ numberRooms }} Quartos 
                </button>

                <div class="dropdown-menu dropdown_passenger_info" aria-labelledby="dropdownMenuButton1">
                    <div class="traveller-calulate-persons">
                        <div class="passengers">
                            <h6>Quartos e Hóspedes</h6>
                            <div class="passengers-types">
                                <div class="passengers-type">
                                    <div class="text"><span class="count pcount">{{
        numberAdults
}}</span>
                                        <div class="type-label">
                                            <p>Adultos</p>
                                            <span>12+
                                                anos</span>
                                        </div>
                                    </div>
                                    <div class="button-set">
                                        <button type="button" class="btn-add" @click.stop="increment">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <button type="button" class="btn-subtract" @click.stop="decrement">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="passengers-type">
                                    <div class="text"><span class="count ccount">{{
        numberChildren
}}</span>
                                        <div class="type-label">
                                            <p class="fz14 mb-xs-0">
                                                Crianças
                                            </p><span>2
                                               a 12 anos
                                                </span>
                                        </div>
                                    </div>
                                    <div class="button-set">
                                        <button type="button" class="btn-add-c" @click.stop="incrementc">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <button type="button" class="btn-subtract-c" @click.stop="decrementc">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="passengers-type">
                                    <div class="text"><span class="count incount">{{
        numberRooms
}}</span>
                                        <div class="type-label">
                                            <p class="fz14 mb-xs-0">
                                                Quartos
                                            </p>
                                        </div>
                                    </div>
                                    <div class="button-set">
                                        <button type="button" class="btn-add-in" @click.stop="incrementi">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <button type="button" class="btn-subtract-in" @click.stop="decrementi">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "CountDropdownHotel",
    data() {
        return {
            number: 1,
            numberAdults: 1,
            numberChildren: 0,
            numberRooms: 1,
        };
    },
    methods: {


        increment() {
            this.number++;
            this.numberAdults++;
            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },

        decrement() {

            if (this.number === 0) return;
            this.number -= 1

            if (this.numberAdults === 0) return;

            this.numberAdults -= 1;

            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },


        incrementc() {
            this.number++;
            this.numberChildren++;
            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },

        decrementc() {

            if (this.number === 0) return
            this.number -= 1

            if (this.numberChildren === 0) return

            this.numberChildren -= 1

            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },


        incrementi() {
            this.numberRooms++;
            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },

        decrementi() {


            if (this.numberRooms === 0) return

            this.numberRooms -= 1;
            this.$emit('count-updated', {numberAdults:this.numberAdults,numberChildren: this.numberChildren, numberRooms:this.numberRooms});

        },


    }
};
</script>