<template>
    <section id="news_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Latest travel news</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="news_area_top_left">
                        <router-link to="/news-details"><img src="../../assets/img/news/news-big.png" alt="img"></router-link>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="news_area_top_right">
                        <h2>
                            <router-link to="/news-details">Veniam ex tempor qui ad amet mollit cillum aliqua aliqua. Fugiat
                                tempor eu magna</router-link>
                        </h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page when looking at its layout. The point of using Lorem Ipsum is that it has a more. Ad
                            minim in commodo fugiat adipisicing cupidatat tempor aliqua.
                        </p>
                        <router-link to="/news-details">Read full article <i class="fas fa-arrow-right"></i></router-link>
                        <div class="news_author_area">
                            <div class="news_author_img">
                                <img src="../../assets/img/news/author-1.png" alt="img">
                            </div>
                            <div class="news_author_area_name">
                                <h4>Melisa campbell</h4>
                                <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new_main_news_box">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-1.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">Revolutionising the travel industry,
                                        one partnership at a time
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Jennifer lawrence</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-2.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">
                                        Nostrud occaecat reprehenderit elit pariatur do occaecat.
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-2.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Connley jimmy</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-3.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">
                                        Ea non officia minim cupidatat culpa et reprehenderit esse ea
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-3.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Astom martin</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-1.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">
                                        Anim labore dolor mollit esse do labore adipisicing fugiat
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Simon donald</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-5.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">
                                        Ex ad cupidatat aliquip nostrud duis deserunt culpa esse
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-5.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Jesica aliston</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/news-details"><img src="../../assets/img/news/news-6.png" alt="img"></router-link>
                            </div>
                            <div class="news_item_content">
                                <h3><router-link to="/news-details">
                                        Occaecat nulla anim cillum anim id irure nostrud elit excepteur nisi
                                    </router-link></h3>
                                <p>Irure enim eiusmod ipsum do Lorem sit consectetur enim consectetur. Nostrud ipsum
                                    eiusmod eiusmod culpa anim excepteur.</p>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../assets/img/news/author-6.png" alt="img">
                                </div>
                                <div class="news_author_area_name">
                                    <h4>Patricia ramise</h4>
                                    <p><router-link to="/news">26 Oct 2021</router-link> <i class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="pagination_area">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "NewsArea"
};
</script>