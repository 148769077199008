<template>
  <FullScreenLoader v-if="isLoading"/>

  <!-- Common Banner Area -->
  <HotelDetailBanner/>

  <!-- Hotel Search Areas -->
  <HotelSearch :hotel="hotel"/>


</template>
<script>
import HotelDetailBanner from '@/components/hotel/HotelDetailBanner.vue'
import HotelSearch from '@/components/hotel/HotelSearch.vue'
import api from '@/api.js';
import FullScreenLoader from '@/components/FullScreenLoader'

export default {
  name: "HotelDetailsView",
  components: {
    HotelDetailBanner, HotelSearch, FullScreenLoader
  },
  data() {
    return {
      hotel: null,
      isLoading: true
    }
  },
  async mounted() {
    const hotelId = this.$route.query.id;
    await api.get(`/hotel/search/${hotelId}?zoneCode=&start=${this.$route.query.from}&end=${this.$route.query.to}&adults=${this.$route.query.adults}&children=${this.$route.query.children}&rooms=${this.$route.query.rooms}`)
        .then(response => {
          this.isLoading = false;
          response = response.data;
          this.hotel = response;
          if (this.hotel.description != null)
            this.hotel.description = this.hotel.description.replace(/\n/g, "<p>");
        });

  }
}
</script>
