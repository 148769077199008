<template>
  <section id="tour_details_main" class="section_padding">
    <div v-if="hotel != null" class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tour_details_leftside_wrapper">
            <div class="tour_details_heading_wrapper">
              <div v-if="hotel[0].address != null" class="tour_details_top_heading">
                <h2>{{ hotel[0].name }}</h2>
                <h5><i class="fas fa-map-marker-alt"></i> {{ hotel[0].address }}</h5>
                <h6> {{ hotel[0].locationText }}</h6>
              </div>
            </div>

            <div v-if="hotel[0].description != null" class="tour_details_boxed">
              <h3 class="heading_theme">Sobre o hotel</h3>
              <div class="tour_details_boxed_inner" v-html="hotel[0].description">
              </div>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Seleção de Quartos</h3>
              <div v-for="(room, index) in hotel[0].rooms" :key="`item-${index}`" class="room_select_area"
                   style="border-bottom:1px solid #8b3eea;padding-bottom:10px;padding-top:20px">

                <div class="room_booking_area" style="display:flex">
                  <div class="room_table_name">
                    <h3 v-if="room.name">
                      <router-link to="/room-booking">{{ room.name?.toLowerCase() }}</router-link>
                    </h3>
                    <h3 v-if="room.rates.length">
                      <router-link to="/room-booking">{{ room.rates[0].name?.toLowerCase() }}</router-link>
                    </h3>
                  </div>
                  <div class="room_table_right_pane">
                    <div v-for="(rate, idx_rate) in room.rates" :key="`item-${idx_rate}`"
                         class="room_table_details">
                      <div style="display:flex;padding-bottom:80px;padding-top:20px">
                        <div class="room_table_price">
                          <h3 v-if="!me.organization">{{ rate.net }}€</h3>
                          <h5>PVP: {{ rate.sellingRate || rate.pvp }}€</h5>
                          <ul>
                            <li v-if="rate.breakfast">Com pequeno almoço</li>
                            <li v-else>Só alojamento</li>
                            <li v-if="rate.noRefund">Não reembolsável</li>
                            <br/><br/><br/>
                            Fornecedor: {{ hotel[0].supplierName }}
                          </ul>
                        </div>


                        <div class="room_table_conditions">
                          <ul>
                            <li v-for="(policy, index) in rate.cancellationPolicies" :key="`item-${index}`">
                              Cancelamento a partir de {{ policy.from.split('T')[0] }} : {{ policy.amount }}€
                            </li>
                            <span v-html="rate.cancellationNotes?.replace('\n','<br/>')"></span>
                          </ul>
                          <div v-html="rate.notes?.replace('\n','<br/>')"></div>


                        </div>
                        <div class="room_table_reserve">
                          <a class="btn btn_theme btn_md"
                             @click="reserveHotel(room, rate)">Reservar</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tour_details_img_wrapper">
              <div class="image__gallery">
                <div class="gallery">
                  <div class="gallery__slideshow">
                    <div class="gallery__slides">
                      <div class="gallery__slide">
                        <img v-for="(slide, index) in gallerySlides" :key="index" :src="slide.img"
                             :style="slideIndex === index
                                                        ? 'display:block;'
                                                        : 'display:none;'
                                                        " alt="" class="gallery__img"/>

                      </div>
                    </div>
                    <a class="gallery__prev" @click.prevent="move(-1)">&#10095;</a>
                    <a class="gallery__next" @click.prevent="move(1)">&#10094;</a>
                  </div>

                  <div class="gallery__content">
                    <div class="gallery__items">

                      <div v-for="(slide, index) in gallerySlides" :key="`item-${index}`" :class="{
                                                'gallery__item--is-acitve': slideIndex === index
                                            }" class="gallery__item">
                        <img :src="slide.img" class="gallery__item-img"
                             @click="currentSlide(index)"/>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="hotel[0].lat != null && hotel[0].lat != 0" class="tour_details_boxed">
              <h3 class="heading_theme">Localização do hotel</h3>
              <div class="map_area">
                <iframe
                    :src="`https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&marker=${hotel.lat}%2C${hotel.lon}&layers=ND`"
                    frameborder="0" height="350" marginheight="0" marginwidth="0"
                    scrolling="no"
                    style="border: 1px solid black"
                    width="425"></iframe>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>

import {inject} from "vue";

export default {
  name: "HotelSearch",
  props: ['hotel'],
  setup() {
    const me = inject('me');
    return {me};
  },
  computed: {
    // a computed getter
    gallerySlides() {
      return this.hotel[0].images.map(x => {
        return {img: x.path}
      });
    },
    slides() {
      return this.hotel[0].images.map(x => {
        return {img: x.path}
      });
    }, bbox() {
      if (this.hotel == null) return '';
      let x = this.getBboxFromCenterAndZoom([this.hotel.lat, this.hotel.lon], 17);
      return `${x[0]}%2C${x[1]}%2C${x[2]}%2C${x[3]}`;
    }
  },

  data() {
    return {
      slideIndex: 0
    }
  },

  methods: {
    reserveHotel(room, rate) {
      this.$router.push({
        path: '/room-booking',
        query: {
          id: this.hotel[0].searchId,
          rate: rate.rateKey
        }
      });
    },
    minRate(roomRates) {
      let minRate = 0;
      roomRates.forEach(rate => {
        if (minRate == 0 || rate.net < minRate) {
          minRate = rate.net;
        }
      });
      return minRate;
    },
    getBboxFromCenterAndZoom(centerCoordinates, zoom) {
      // Get the coordinates of the four corners of the map
      // based on the center coordinates and zoom level
      let lat = centerCoordinates[0];
      let lng = centerCoordinates[1];

      // Earth's circumference at the equator is ~40,075 km
      // 1° of latitude is 111.32 km
      // 1° of longitude at the equator is ~111.32 km
      // cosine of latitude is used for longitude at other latitudes
      let latDegree = 111.32;
      let lngDegree = 111.32 * Math.cos(lat * Math.PI / 180);

      // Get the coordinates of the four corners
      let latOffset = (1 / Math.pow(2, zoom)) * latDegree;
      let lngOffset = (1 / Math.pow(2, zoom)) * lngDegree;
      let southWest = [lat - latOffset, lng - lngOffset];
      let northEast = [lat + latOffset, lng + lngOffset];

      // return the bbox
      return [southWest[1], southWest[0], northEast[1], northEast[0]];
    },

    move(n) {
      if (this.gallerySlides.length <= this.slideIndex + n) {
        this.slideIndex = 0
      } else if (this.slideIndex + n < 0) {
        this.slideIndex = this.gallerySlides.length - 1
      } else {
        this.slideIndex += n
      }
    },
    currentSlide(index) {
      this.slideIndex = index
    },

  }
};
</script>
