<template>
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <h3 class="heading_theme">Informação da reserva</h3>
              <div class="tour_booking_form_box">
                <div class="your_info_arae">
                  <ul>
                    <li><span class="name_first">Primeiro nome:</span> <span
                        class="last_name">{{ firstName }}</span></li>
                    <li><span class="name_first">Último nome:</span> <span
                        class="last_name">{{ lastName }}</span></li>
                    <li><span class="name_first">Email:</span> <span
                        class="last_name">{{ email }}</span></li>
                    <li><span class="name_first">Telemóvel:</span> <span
                        class="last_name">{{ phone }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="col-lg-4">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Detalhe da Reserva</h3>
                </div>
                <div class="tour_booking_amount_area">
                  <ul>
                    <li>{{ hotelName }}</li>
                    <li>ID Reserva: <span class="underline">{{ supplierBookReference }}</span></li>
                    <li>Date Reserva: <span>{{ date.split('T')[0] }}</span></li>
                  </ul>
                  <ul>
                    <li>Checkin: <span>{{ this.fromDate }}</span></li>
                    <li>Checkout: <span>{{ this.toDate }}</span></li>
                    <li>{{ this.nights }} Noite(s) <span>{{ total }}€</span></li>
                  </ul>


                  <div class="total_subtotal_booking">
                    <h6 class="remove_coupon_tour">Total <span>{{ total }}€</span></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="me && !(me.organization && me.organization.length)" class="row">
        <div class="row">
          <div class="col-lg-12">
            <div class="tou_booking_form_Wrapper">
              <div class="booking_tour_form">
                <h3 class="heading_theme">Dados SIGAV</h3>
                <div class="tour_booking_form_box">
                  <form id="tour_bookking_form_item" action="!#">
                    <div class="row">
                      <div class="col-lg-2">
                        <div class="form-group">
                          <label>Balcão</label>
                          <input v-model="selectedDepartment" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-8">
                        <div class="form-group">
                          <label>Ficheiro</label>
                          <input v-model="sigavFile" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="form-group">
                          <label>Ano</label>
                          <input v-model="year" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Fornecedor Sigav</label>
                          <input v-model="selectedSupplier" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Cliente Sigav</label>
                          <input v-model="selectedClient" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Aeroporto Mais Próximo</label>
                          <input v-model="airport" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Valor de Venda</label>
                          <input v-model="saleValue" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Taxa de Emissão</label>
                          <input v-model="fee" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Motivo Tarifa</label>
                          <input v-model="selectedMotive" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Motivo Fee</label>
                          <input v-model="selectedFee" class="form-control bg_input" readonly type="text">

                        </div>
                      </div>


                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Observações</label>
                          <input v-model="remarks" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Centro de Custo</label>
                          <input v-model="costCenter" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Departamento</label>
                          <input v-model="department" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Nº de Projecto</label>
                          <input v-model="projectNumber" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Pedido por</label>
                          <input v-model="requestedBy" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Requisição</label>
                          <input v-model="requisition" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Unidade Contabilística</label>
                          <input v-model="accountingUnit" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Número contabilístico</label>
                          <input v-model="accountingNumber" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Número de Empregado</label>
                          <input v-model="employeeNumber" class="form-control bg_input" readonly type="text">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>
import api from '@/api.js';
import {inject} from 'vue';

export default {
  name: "TourBooking",
  data() {
    return {
      hotel: null,
      room: null,
      rate: null,
      from: null,
      to: null,
      adults: null,
      children: null,
      rooms: null,
      bookingId: null,
      rateId: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      total: '',
      loading: true,
      supplierBookReference: '',
      hotelName: '',
      costCenter: '',
      department: '',
      projectNumber: '',
      requestedBy: '',
      employeeNumber: '',
      accountingNumber: '',
      accountingUnit: '',
      requisition: '',
      remarks: '',
      selectedMotive: '7fbf82e3-f40e-4c1a-ba60-79b5cc1137da',
      selectedFee: '6eb9332c-2598-4893-9a3f-5497f95bb55c',
      selectedSupplier: '',
      sigavFile: '',
      year: '',
      airport: '',
      saleValue: '',
      fee: '0.0',
      motives: [],
      fees: [],
      departments: [],
      availableYears: [],
      suppliers: [],
      clients: [],
      selectedClient: null,
      selectedDepartment: null
    }
  }, setup() {
    const me = inject('me');
    return {me};
  },
  methods: {},
  computed: {},
  async mounted() {
    this.bookingId = this.$route.query.id;

    await api.get(`/hotel/booking/${this.bookingId}`)
        .then(response => {
          response = response.data;
          this.loading = false;
          this.firstName = response.firstName;
          this.lastName = response.lastName;
          this.phone = response.phone;
          this.email = response.email;
          this.date = response.dateCreated;
          this.total = response.total;
          this.saleValue = response.total;
          this.hotelName = response.hotelName;
          this.fromDate = response.fromDate;
          this.toDate = response.toDate;
          this.airport = response.closestAirport;
          this.supplierBookReference = response.supplierBookReference;
          //calculate nights using fromDate and toDate:
          const date1 = new Date(this.fromDate);
          const date2 = new Date(this.toDate);
          this.nights = Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
          if (response.sigav != null) {
            this.selectedDepartment = response.sigav.department;
            this.sigavFile = response.sigav.file;
            this.year = response.sigav.year;
            this.selectedFee = response.sigav.fee;
            this.selectedSupplier = response.sigav.supplierId;
            this.selectedClient = response.sigav.client;
            this.documentNumber = response.sigav.documentNumber;
            this.remarks = response.sigav.remarks;
            this.costCenter = response.sigav.costCenter;
            this.projectNumber = response.sigav.projectNumber;
            this.requestedBy = response.sigav.requestedBy;
            this.requisition = response.sigav.requisition;
            this.accountingUnit = response.sigav.accountingUnit;
            this.accountingNumber = response.sigav.accountingNumber;
            this.employeeNumber = response.sigav.employeeNumber;
            this.sigavStatus = response.sigav.status;
            this.selectedMotive = response.sigav.motive;
          }
        });
  }
};
</script>
<style scoped>
.underline {
  text-decoration: underline;
}
</style>
