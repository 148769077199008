<template>
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div id="myTabContent" class="tab-content">
              <div id="hotels" aria-labelledby="hotels-tab" class="tab-pane fade  show active"
                   role="tabpanel">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="tour_search_form">
                      <form action="#!">
                        <div class="row">
                          <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div class="">
                              <p>Destino</p>
                              <model-list-select v-model="selectedCountry" :class="isLoading ? 'loading' : ''"
                                                 :customText="buildDestinationName" :list="countries"
                                                 option-text="name"
                                                 option-value="id"
                                                 placeholder="Onde deseja ir?"
                                                 @searchchange="asyncFind">
                              </model-list-select>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="form_search_date">
                              <div>
                                <div class="Journey_date">
                                  <p>Check In - Check Out</p>
                                  <div>
                                    <Datepicker v-model="date" :enable-time-picker="false" :min-date="new Date()"
                                                cancel-text="Cancelar"
                                                format="dd-MM-yyyy"
                                                locale="pt-PT"
                                                range
                                                select-text="Escolher"/>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <CountDropdownHotel @count-updated="handleCountUpdated"/>
                          <div class="top_form_search_button">
                            <router-link v-if="selectedCountry && date && date.length === 2"
                                         :to="generateLink()"
                                         class="btn btn_theme btn_md">
                              Pesquisar
                            </router-link>

                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import {ModelListSelect} from "vue-search-select"
import CountDropdown from '@/components/home/CountDropdown.vue'
import CountDropdownRound from '@/components/home/CountDropdownRound.vue'
import CountDropdownMulti from '@/components/home/CountDropdownMulti.vue'
import CountDropdownTour from '@/components/home/CountDropdownTour.vue'
import CountDropdownHotel from '@/components/home/CountDropdownHotel.vue'
import "vue-search-select/dist/VueSearchSelect.css"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Cookies from 'js-cookie';

export default {
  name: "Form",

  components: {
    ModelListSelect,
    CountDropdown,
    CountDropdownRound,
    CountDropdownMulti,
    CountDropdownTour,
    CountDropdownHotel,
    Datepicker
  },
  mounted: function () {
    console.log(Cookies.get('token'));
    if (!Cookies.get('token')) {
      this.$router.push('/login');
    }

    this.isLogged = true;
  },
  data() {
    return {
      isLogged: true,
      isHidden: false,
      rowData: [{name: ''}],
      selectedCountry: null,
      countries: [],
      date: null,
      isLoading: false,
      checkin: this.dateFormat(new Date()),
      checkout: this.dateFormat(new Date()),
      guests: {numberAdults: 1, numberChildren: 0, numberRooms: 1}
    };
  },
  methods: {
    generateLink() {
      let retval = "";
      if (this.selectedCountry.length < 30) {
        retval = `hotel-search/${this.selectedCountry},${this.formatDate(this.date[0])},${this.formatDate(this.date[1])},${this.guests.numberAdults},${this.guests.numberChildren},${this.guests.numberRooms}`;
      } else {
        retval = `hotel-detail?id=${this.selectedCountry}&from=${this.date[0].toISOString().split('T')[0]}&to=${this.date[1].toISOString().split('T')[0]}&adults=${this.guests.numberAdults}&children=${this.guests.numberChildren}&rooms=${this.guests.numberRooms}`;
      }

      console.log(retval);
      return retval;
    },
    formatDate(date) {
      return date.toLocaleDateString('pt-PT').replace(/[\/-]/g, ',');
    },
    handleCountUpdated(newCount) {
      this.guests = newCount;
    },
    dateFormat(date) {
      const mm = date.getMonth() + 1;
      const dd = date.getDate();
      const yyyy = date.getFullYear();
      return `${mm}/${dd}/${yyyy}`;
    },
    buildDestinationName({name, parentName}) {
      if (parentName != null)
        return `${name}, ${parentName}`
      else
        return name;
    },

    searchHotels() {
      console.log(this.selectedCountries.get());
    },
    asyncFind(query) {


      if (query.length < 3) {
        return;
      }

      this.isLoading = true;

      fetch(`https://ars.alivetravel.com/api/destination/search2?search=${query}`, {
        method: 'GET'
      })
          .then(res => res.json())
          .then(response => {
            this.countries = response
            this.isLoading = false;
          })
    },
    clearAll() {
      this.selectedCountries = []
    }

  }
};
</script>


