<template>
    <section id="common_author_area" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="common_author_boxed">
                        <div class="common_author_heading">
                            <h3>Reset password</h3>
                            <h2>Reset you password</h2>
                        </div>
                        <div class="common_author_form">
                            <form action="#" id="main_author_form">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="New password" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Old password" />
                                </div>
                                <div class="common_form_submit">
                                    <button class="btn btn_theme btn_md">Reset password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
    
<script>

export default {
    name: "CommonAuthorTwo"
};
</script>