<template>
  <FullScreenLoader v-if="isLoading"/>

  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_form">
              <form id="main_author_form" action="#">
                <div class="common_form_submit" style="display: flex;flex-direction: column">
                  <button class="btn btn_theme btn_md" style="margin-bottom: 10px" type="button" @click="login()">ARS
                    Login
                  </button>
                  <a class="btn btn_theme btn_md" href="/login-external">Login Credenciais</a>
                </div>
                <!--  <div class="form-group">
                      <input type="text" class="form-control" placeholder="Nome de utilizador"
                          v-model="username" />
                  </div>
                  <div class="form-group">
                      <input type="password" class="form-control" placeholder="Password" v-model="password" />
                      <router-link to="/forgot-password">Esqueci a password</router-link>
                  </div>
                  <div class="form-group" v-if="error">
                      <div class="error text-center">Nome de utilizador ou password inválidos.</div>
                  </div>
                  <div class="common_form_submit">
                      <button type="button" class="btn btn_theme btn_md" @click="login()">Entrar</button>
                  </div>

              -->
                <!--
                <div class="have_acount_area">
                    <p>Dont have an account? <router-link to="/register">Register now</router-link></p>
                </div>
            -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from 'js-cookie';
import api from '@/api';
import FullScreenLoader from '@/components/FullScreenLoader'
import {useEventBus} from '@vueuse/core'
import {mapInjectionKey} from "@/useCookie";
import {PublicClientApplication} from "@azure/msal-browser";


export default {
  components: {
    FullScreenLoader,
  },
  name: "CommonAuthorFour",

  data() {
    const bus = useEventBus(mapInjectionKey)


    return {
      username: '',
      password: '',
      isLoading: false,
      error: false,
      bus: bus
    };
  },
  mounted() {
    if (this.cookieValue) {
      console.log(this.cookieValue);
      this.$router.push('/');
    }
  },
  methods: {
    async login() {
      const clientId = "cfde8293-1f2c-4920-8d04-cd9045dac8b1";
      const authority = "https://login.microsoftonline.com/a7fde2e0-a34c-4e84-8bea-ad01bc57db49";
      const scopes = ["user.read"]; // The desired scopes you want to access

      const app = new PublicClientApplication({
        auth: {
          clientId: clientId,
          authority: authority
        }
      });

      try {
        const response = await app.loginPopup({
          scopes: scopes
        });

        this.isLoading = true;
        this.error = false;
        api.post('/auth/login_external', {
          token: response.accessToken
        })
            .then(response => {
              this.isLoading = false;
              Cookies.set('token', response.data.token, {expires: 10000000});
              this.bus.emit(response.data.token);
              window.location.href = '/';
            })
            .catch(error => {
              this.isLoading = false;
              this.error = true;
              console.log(error);
            });

        // Handle successful login, e.g., store the access token in Vuex or local storage
      } catch (error) {
        console.error("Error during login:", error);
        // Handle error, e.g., show error message to the user
      }
    },
    updateCookie(newValue) {
      Cookies.set('token', newValue);
      EventBus.emit('cookie-updated', newValue);
    },
    login2() {
      this.isLoading = true;
      this.error = false;
      api.post('/auth/login', {
        username: this.username,
        password: this.password
      })
          .then(response => {
            this.isLoading = false;
            Cookies.set('token', response.data.token, {expires: 10000000});
            this.bus.emit(response.data.token);
            window.location.href = '/';
          })
          .catch(error => {
            this.isLoading = false;
            this.error = true;
            console.log(error);
          });
    }
  },
};
</script>
