<template>
    <section id="how_it_work_area" class="section_padding">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>How it works</h2>
                    </div>
                </div>
            </div>
            <div class="row">
               
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="how_it_boxed">
                        <img src="../../assets/img/common/how-2.png" alt="img">
                        <h3>Contact with client</h3>
                        <p>Duis laboris culpa cupidatat do consequat esse officia ex.
                            Reprehenderit quis est id sint ea dolore sint nostrud demos adipisicing.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="how_it_boxed">
                        <img src="../../assets/img/common/how-3.png" alt="img">
                        <h3>Start earnings</h3>
                        <p>Duis laboris culpa cupidatat do consequat esse officia ex.
                            Reprehenderit quis est id sint ea dolore sint nostrud demos adipisicing.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "HowWork"
};
</script>