<template>
  <div>
    <header class="main_header_arae">
      <!-- Top Bar -->
      <div class="topbar-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
            </div>
            <div class="col-lg-6 col-md-6">
              <ul class="topbar-others-options">
                <li v-if="!isLogged">
                  <router-link to="/login">Login</router-link>
                </li>
                <li v-if="isLogged" style="color:white;cursor:pointer">
                  <router-link to="/hotel-booking">Histórico</router-link>
                </li>
                <li v-if="isLogged" style="color:white;cursor:pointer"><a @click="logOut">Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Navbar Bar -->
      <div :class="{ 'is-sticky': isSticky }" class="navbar-area">
        <div class="main-responsive-nav">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo">
                    <router-link to="/">
                      <img alt="logo" src="../assets/img/logo.png" style="height:50px">
                    </router-link>
                  </div>
                  <div v-if="user" class="logo right-image">
                    <img v-if="user.organization" :src='user.logo' alt="logo" style="height:50px">
                  </div>
                  <button class="navbar-toggler collapsed" data-bs-target="#navbar-content" data-bs-toggle="collapse"
                          type="button">
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div id="navbar-content" class="collapse navbar-collapse mean-nav">
                    <ul class="navbar-nav mr-auto mb-2 mb-lg-0">

                      <li class="nav-item dropdown">
                        <router-link class="dropdown-item dropdown-toggle" data-bs-auto-close="outside"
                                     data-bs-toggle="dropdown"
                                     to="/">Flights
                        </router-link>
                        <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link class="dropdown-item"
                                         to="/flight-search-result">Flight
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link class="dropdown-item"
                                         to="/flight-booking-submission">Flight
                              Booking
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item dropdown">
                        <router-link class="dropdown-item dropdown-toggle" data-bs-auto-close="outside"
                                     data-bs-toggle="dropdown" to="/">Hotel
                        </router-link>
                        <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link class="dropdown-item"
                                         to="/hotel-search">Hotel
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link class="dropdown-item" to="/hotel-details">Hotel
                              Booking
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link class="dropdown-item" to="/room-details">Room
                              Details
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link class="dropdown-item" to="/room-booking">Room
                              Booking
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <router-link class="dropdown-item" to="/contact">Contact</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light flex-container">
              <router-link class="navbar-brand" to="/">
                <img alt="logo" src="../assets/img/logo.png" style="height:75px">
              </router-link>
              <div class="logo right-image">
                <img v-if="user && user.organization" :src='user.logo' alt="logo" style="height:50px">
              </div>
            </nav>
          </div>

        </div>
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="container">
              <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <a class="search-box" href="#"><i class="fas fa-search"></i></a>
                  </div>
                  <div class="option-item">
                    <router-link class="btn btn_navber" to="/contact">Get free quote</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import {useEventBus} from '@vueuse/core'
import {mapInjectionKey} from "@/useCookie";
import {inject} from "vue";


export default {
  name: 'Header',
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      language: 'English',
      currency: 'USD',
      isSticky: false,
      isLogged: false
    }
  },
  setup() {
    const me = inject('me');
    return {me};
  },
  methods: {
    logOut() {
      this.isLogged = false;
      Cookies.remove('token');
      window.location.href = '/';
    }
  },
  mounted() {

    this.isLogged = Cookies.get('token') || null;

    const bus = useEventBus(mapInjectionKey);
    bus.on((e) => {
      this.isLogged = (e != null);
    });


    window.addEventListener('scroll', () => {
      let scroll = window.scrollY
      if (scroll >= 200) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    })

    document.addEventListener('click', function (e) {
      // Hamburger menu
      if (e.target.classList.contains('hamburger-toggle')) {
        e.target.children[0].classList.toggle('active');
      }
    })
  }
}
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between; /* This will place one item at the start and the other at the end of the container */
  align-items: center; /* This will align items vertically in the center */
  width: 100%; /* Set the width of the container */
  /* You can add additional styling like padding, border, etc. */
}

.flex-container img {
  max-width: 100%;
  height: auto; /* This will maintain the aspect ratio of the images */
  /* You can add additional styling for the images if needed */
}

/* Additional classes for specific styling of left and right images, if needed */
.left-image {
  /* Styles for the left image */
}

.right-image {
  background: white;
}


</style>
```
