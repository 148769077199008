<template>
  <FullScreenLoader v-if="isLoading"/>
  <section id="dashboard_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashboard_common_table">
            <h3>Reservas</h3>
            <div class="table-responsive-lg table_common_area">
              <table class="table">
                <thead>
                <tr>
                  <th>Reserva ARS</th>
                  <th v-if="!me.organization">File SIGAV</th>
                  <th>Fornecedor</th>
                  <th>Data</th>
                  <th>Referência</th>
                  <th>Hotel</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Data Cancel.</th>
                  <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='booking in bookings' :key="booking.id">
                  <td>{{ booking.internalId }}</td>
                  <td v-if="!me.organization && booking.sigavFile.length > 1">{{ booking.sigavFile }}</td>
                  <td v-if="!me.organization && booking.sigavFile.length == 1" class="text-danger">Por Integrar</td>
                  <td>{{ booking.supplierName }}</td>
                  <td>{{ formatDate(booking.dateCreated) }}</td>
                  <td>{{ booking.supplierBookReference }}</td>
                  <td>{{ booking.hotelName }}</td>
                  <td>{{ booking.total }}€</td>
                  <td :class="this.statusClass(booking.status)">{{ this.convertStatus(booking.status) }}</td>
                  <td>{{ formatDate(booking.dateCancelled) }}</td>
                  <td>
                    <i v-if="booking.status == 'CONFIRMED'" class="fas fa-ban"
                       @click="this.showCancelConfirmation(booking.id)"></i>
                    <a v-if="me.organization" :href="`/booking-details?id=${booking.id}`" target="_blank"> <i
                        class="fas fa-eye"></i> </a>
                    <a v-if="!me.organization && booking.sigavFile.length > 1"
                       :href="`/booking-details?id=${booking.id}`" target="_blank"> <i class="fas fa-eye"></i> </a>
                    <a v-if="!me.organization && booking.sigavFile.length == 1"
                       :href="`/booking-confirmation?id=${booking.id}`" target="_blank"> <i class="fas fa-edit"></i>
                    </a>
                    <a v-if="!me.organization" :href="`${booking.voucherUrl}`"> <i
                        class="fas fa-download"></i></a>
                    <a v-if="me.organization" :href="`${booking.voucherUrlBasic}`" target="_blank"> <i
                        class="fas fa-download"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-if="showConfirmationPopup" class="confirmation-popup">
    <div class="popup-content">
      <h3>Cancelar Reserva</h3>
      <p>Escrever 'confirmo' para cancelar:</p>
      <input
          v-model="confirmationInput"
          :class="{ 'invalid-input': confirmationInput !== 'confirmo','valid-input': confirmationInput === 'confirmo' }"
          type="text"
      />
      <div class="buttons">
        <button class="confirm-button" @click="cancelBookingConfirmation">Confirm</button>
        <button class="cancel-button" @click="cancelConfirmation">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'
import api from '@/api.js';
import MyBookingOption from '@/components/dashboard/MyBookingOption.vue'
import FullScreenLoader from '@/components/FullScreenLoader'
import {inject} from "vue";

export default {
  name: "HotelBookingDashboard",
  components: {
    LogoutBtn, MyBookingOption, FullScreenLoader
  },
  data() {
    return {
      bookings: [],
      isLoading: true,
      showConfirmationPopup: false,
      confirmationInput: "",
    }
  },
  setup() {
    const me = inject('me');
    return {me};
  },
  computed: {
    statusClass() {
      return function (s) {
        if (s == 'NEW') {
          return 'text-warning'
        }
        if (s == 'CONFIRMED') {
          return 'text-success'
        }
        if (s == 'CANCELLED') {
          return 'text-danger'
        }
      }
    }
  },
  methods: {
    showCancelConfirmation(bookingId) {
      this.showConfirmationPopup = true;
      this.confirmationInput = "";
      this.bookingToCancel = bookingId;
    },

    cancelBookingConfirmation() {
      if (this.confirmationInput === "confirmo") {
        this.cancelBooking(this.bookingToCancel);
        this.cancelConfirmation();
      } else {

      }
    },

    cancelConfirmation() {
      this.showConfirmationPopup = false;
      this.confirmationInput = "";
      this.bookingToCancel = null;
    },
    cancelBooking(b) {
      api.delete(`/hotel/bookings/${b}`)
          .then(response => {
            this.bookings = this.bookings.map(booking => {
              if (booking.id == b) {
                booking.status = 'CANCELLED'
              }
              return booking;
            })
          })
    },
    convertStatus(s) {
      if (s == 'NEW') {
        return 'Pendente'
      }
      if (s == 'CONFIRMED') {
        return 'Confirmado'
      }
      if (s == 'CANCELLED') {
        return 'Cancelado'
      }
    }, formatDate(d) {
      if (d == null || d == undefined || d == "")
        return 'N/A';

      return d.split('T')[0];
    }
  },
  async mounted() {
    await api.get(`/hotel/bookings`)
        .then(response => {
          this.isLoading = false;
          this.bookings = response.data;
        });

  }
};
</script>
<style scoped>
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #ccc;
  color: black;
  border: none;
}

.invalid-input {
  border: 2px solid red !important;;
}

.valid-input {
  border: 2px solid green !important;;
}

input:focus {
  outline: none;
}

i {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
