<template>
     <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                        <div class="terms_item">
                            <h4>Overview</h4>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda officia vel omnis,
                                odit quidem, expedita nam deserunt molestiae accusamus voluptas aut. Sapiente voluptatem
                                nulla unde quia harum illum ipsum dolore! Lorem ipsum dolor sit amet consectetur
                                adipisicing elit.
                                In dolorem illum molestiae corrupti, maxime sint velit quibusdam officiis ipsam a minima
                                quos
                                voluptates possimus eaque, vitae, veniam consequuntur! Dolorem, architecto.
                            </p>
                            <p>
                                Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet
                                quam
                                vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Vestibulum ante
                                ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                                neque,
                                auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Sed porttitor lectus nibh</h4>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda officia vel omnis,
                                odit quidem, expedita nam deserunt molestiae accusamus voluptas aut. Sapiente voluptatem
                                nulla unde quia harum illum ipsum dolore! Lorem ipsum dolor sit amet consectetur
                                adipisicing elit.
                                In dolorem illum molestiae corrupti, maxime sint velit quibusdam officiis ipsam a minima
                                quos
                                voluptates possimus eaque, vitae, veniam consequuntur! Dolorem, architecto.
                            </p>
                            <p>
                                Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet
                                quam
                                vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Vestibulum ante
                                ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                                neque,
                                auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Vivamus magna justo</h4>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda officia vel omnis,
                                odit quidem, expedita nam deserunt molestiae accusamus voluptas aut. Sapiente voluptatem
                                nulla unde quia harum illum ipsum dolore! Lorem ipsum dolor sit amet consectetur
                                adipisicing elit.
                                In dolorem illum molestiae corrupti, maxime sint velit quibusdam officiis ipsam a minima
                                quos
                                voluptates possimus eaque, vitae, veniam consequuntur! Dolorem, architecto.
                            </p>
                            <p>
                                Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet
                                quam
                                vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Vestibulum ante
                                ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                                neque,
                                auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Overview</h4>
                            <p>
                                Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet
                                quam
                                vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Vestibulum ante
                                ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                                neque,
                                auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Curabitur arcu erat, </h4>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda officia vel omnis,
                                odit quidem, expedita nam deserunt molestiae accusamus voluptas aut. Sapiente voluptatem
                                nulla unde quia harum illum ipsum dolore! Lorem ipsum dolor sit amet consectetur
                                adipisicing elit.
                                In dolorem illum molestiae corrupti, maxime sint velit quibusdam officiis ipsam a minima
                                quos
                                voluptates possimus eaque, vitae, veniam consequuntur! Dolorem, architecto.
                            </p>
                            <p>
                                Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet
                                quam
                                vehicula elementum sed sit amet dui. Nulla porttitor accumsan tincidunt. Vestibulum ante
                                ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
                                neque,
                                auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
    name: "PrivacyPolicyContent"
};
</script>