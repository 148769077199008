<template>
  <FullScreenLoader v-if="isLoading"/>
  <Header :user='currentUser'/>
  <router-view/>
  <Footer/>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import api from '@/api.js';
import FullScreenLoader from "@/components/FullScreenLoader.vue";
import {ref, provide} from 'vue';

export default {
  name: 'App',
  components: {
    FullScreenLoader,
    Header, Footer
  },
  data() {
    return {
      currentUser: null,
      isLoading: true
    }
  },
  async created() {
    const me = ref(null); // create a reactive reference
    provide('me', me); // provide the reactive reference

    await api.get(`/auth/me`)
        .then(response => {
          this.isLoading = false;
          this.currentUser = response.data;
          me.value = this.currentUser;
        }).catch(error => {
          this.isLoading = false;
          this.currentUser = null;
          me.value = this.currentUser;
        });

    return {me};
  },
  methods: {
    checkAuth() {

    }
  },
}
</script>
