<template>

  <SearchLogsDashboard/>

</template>
<script>

import HotelBookingBanner from '@/components/dashboard/HotelBookingBanner.vue'
import HotelBookingDashboard from '@/components/dashboard/HotelBookingDashboard.vue'
import Cta from '@/components/home/Cta.vue'
import SearchLogsDashboard from "@/components/dashboard/SearchLogsDashboard.vue";

export default {
  name: "SearchLogView",
  components: {
    HotelBookingBanner, SearchLogsDashboard, Cta
  }
};
</script>
