<template>
  <FullScreenLoader v-if="isLoading"/>
  <section id="dashboard_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashboard_common_table">
            <h3>Pesquisas</h3>
            <div class="table-responsive-lg table_common_area">
              <table class="table">
                <thead>
                <tr>
                  <th>SessionId</th>
                  <th>Pesquisa</th>
                  <th>Dados</th>
                  <th>Data</th>
                  <th>Supplier</th>
                  <th>Utilizador</th>
                  <th>Pedido</th>
                  <th>Resposta</th>
                  <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(group, sessionId, index) in logs" :key="sessionId">
                  <tr v-for="(log, idx) in group" :key="log.id"
                      :class="{'group-start': isGroupStart(idx), 'group-end': isGroupEnd(group, idx), 'group-row': true}">
                    <td>{{ sessionId }}</td>
                    <td>{{ log.search }}</td>
                    <td v-html="formatSearchData(log.searchData)"></td>
                    <td>{{ log.date }}</td>
                    <td>{{ log.supplierName }}</td>
                    <td>{{ log.userName }}</td>
                    <td>
                      <button @click="downloadXML(log.parentId)">Download</button>
                    </td>
                    <td>
                      <button v-if="log.parentId" @click="downloadXML(log.requestId)">Download</button>
                    </td>
                    <td><!-- Actions go here --></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="showModal = false">&times;</span>
      <pre>{{ xmlContent }}</pre>
    </div>
  </div>
</template>
<script>
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'
import api from '@/api.js';
import MyBookingOption from '@/components/dashboard/MyBookingOption.vue'
import FullScreenLoader from '@/components/FullScreenLoader'
import {inject} from "vue";
import axios from "axios";

export default {
  name: "SearchLogsDashboard",
  components: {
    LogoutBtn, MyBookingOption, FullScreenLoader
  },
  data() {
    return {
      logs: [],
      isLoading: true,
      showConfirmationPopup: false,
      confirmationInput: "",
      showModal: false,
      xmlContent: ""
    }
  },
  setup() {
    const me = inject('me');
    return {me};
  },
  computed: {},
  methods: {
    isGroupStart(index) {
      // Apply group-start class to the first row of each group
      return index === 0;
    },
    isGroupEnd(group, index) {
      // Apply group-end class to the last row of each group
      return index === group.length - 1;
    },
    async downloadXML(searchId) {
      try {
        const response = api.get(`/logs/request/${searchId}`).then(response => {
          this.xmlContent = response.data;
          this.showModal = true;
        });
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    },
    formatSearchData(searchData) {
      if (!searchData) return 'No Data';
      const {start, end, zoneCode, adults, children, rooms, hotelId} = searchData;
      return `
        Start: ${start} End: ${end} <br>
        Zone: ${zoneCode || 'N/A'} Adults: ${adults} <br>
        Children: ${children} Rooms: ${rooms} <br>
        Hotel ID: ${hotelId || 'N/A'}
      `;
    }
  },
  mounted: async function () {
    await api.get(`/logs`)
        .then(response => {
          this.isLoading = false;
          const searchIds = new Set(response.data.map(log => log.parentId));
          const filteredLogs = response.data.filter(log => log.parentId || !searchIds.has(log.requestId));

          const groupedLogs = {};
          filteredLogs.forEach(log => {
            if (!groupedLogs[log.sessionId]) {
              groupedLogs[log.sessionId] = [];
            }
            groupedLogs[log.sessionId].push(log);
          });
          this.logs = groupedLogs;
        })
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  overflow: auto;
  height: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Scoped styles for the dashboard */
.group-start {
  background-color: #f0f0f0; /* Light gray background for the start of a new group */
  border-top: 2px solid #333; /* Dark line to mark the start of a new group */
}

.group-row {
  background-color: #fafafa; /* Slightly lighter rows for the same group */
}

.group-end {
  border-bottom: 2px solid #333; /* Dark line to mark the end of a group */
}

</style>
