import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: 'https://ars.alivetravel.com/api', // Set your API base URL
});

// Add an interceptor to add the token to request headers
api.interceptors.request.use(config => {
    const token = Cookies.get('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (config.method === 'post') {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});


// Add an interceptor to handle 401 responses
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            Cookies.remove('token');
        }
        return Promise.reject(error);
    }
)

export default api;
