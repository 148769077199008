<template>
   <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Escolha de Quartos</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "HotelDetailBanner"
};
</script>