<template>
  <FullScreenLoader v-if="isLoading"/>

  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_form">
              <form id="main_author_form" action="#">
                <div class="form-group">
                  <input v-model="username" class="form-control" placeholder="Email"
                         type="text"/>
                </div>
                <div class="form-group">
                  <input v-model="password" class="form-control" placeholder="Password" type="password"/>
                  <router-link to="/forgot-password">Esqueci a password</router-link>
                </div>
                <div v-if="error" class="form-group">
                  <div class="error text-center">Nome de utilizador ou password inválidos.</div>
                </div>
                <div class="common_form_submit">
                  <button class="btn btn_theme btn_md" type="button" @click="login()">Entrar</button>
                </div>


                <!--
                <div class="have_acount_area">
                    <p>Dont have an account? <router-link to="/register">Register now</router-link></p>
                </div>
            -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from 'js-cookie';
import api from '@/api';
import FullScreenLoader from '@/components/FullScreenLoader'
import {useEventBus} from '@vueuse/core'
import {mapInjectionKey} from "@/useCookie";
import {PublicClientApplication} from "@azure/msal-browser";


export default {
  components: {
    FullScreenLoader,
  },
  name: "EmailLogin",

  data() {
    const bus = useEventBus(mapInjectionKey)
    return {
      username: '',
      password: '',
      isLoading: false,
      error: false,
      bus: bus
    };
  },
  mounted() {
    if (this.cookieValue) {
      console.log(this.cookieValue);
      this.$router.push('/');
    }
  },
  methods: {
    updateCookie(newValue) {
      Cookies.set('token', newValue);
      EventBus.emit('cookie-updated', newValue);
    },
    login() {
      this.isLoading = true;
      this.error = false;
      api.post('/auth/login', {
        username: this.username,
        password: this.password
      })
          .then(response => {
            this.isLoading = false;
            Cookies.set('token', response.data.token, {expires: 10000000});
            this.bus.emit(response.data.token);
            window.location.href = '/';
          })
          .catch(error => {
            this.isLoading = false;
            this.error = true;
            console.log(error);
          });
    }
  },
};
</script>
